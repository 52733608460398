import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { navigate, PageProps } from 'gatsby'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Division,
  useDeleteDivisionMutation,
  useGetDivisionQuery,
  useUpdateDivisionMutation,
} from 'manage-tritag/services/api/endpoints/divisions'
import { useEffect, useState } from 'react'
import DeleteModal from 'manage-tritag/components/modal/delete_modal'
import Spinner from 'manage-tritag/components/loading/spinner'
import {
  FormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
} from './styles'

const DivisionEditForm = (props: PageProps) => {
  const { params } = props

  const [updateDivision, { isLoading: updateLoading }] =
    useUpdateDivisionMutation()
  const [deleteDivision] = useDeleteDivisionMutation()
  const [modal, setModal] = useState(false)
  const { data: divisionData, isLoading: divisionLoading } =
    useGetDivisionQuery(params?.id)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: '',
      ageFrom: 0,
      ageTo: 0,
      gender: '',
      gameDuration: '',
      status: false,
    },
  })

  useEffect(() => {
    if (divisionData) {
      reset({
        name: divisionData.name,
        ageFrom: divisionData.ageFrom,
        ageTo: divisionData.ageTo,
        gender: divisionData.gender,
        gameDuration: divisionData.gameDuration.toString(),
        status: divisionData.status,
      })
    }
  }, [reset, divisionData])

  const onSubmit: SubmitHandler<Division> = async (updateData: any) => {
    if (updateLoading) return
    await updateDivision({ ...updateData, id: params?.id }).unwrap()
    reset()
    toast.success('Division updated succesfully.')
    navigate('/divisions')
  }

  const handleDelete = async () => {
    setModal(false)
    await deleteDivision(params?.id).unwrap()
    toast.success('Division deleted succesfully.')
    navigate('/divisions')
  }

  if (divisionLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container spacing={3} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Division details
          </Typography>
          <FormContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="ageFrom"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  inputProps={{ tabIndex: 2 }}
                  InputProps={{ disableUnderline: true }}
                  type="number"
                  id="filled-basic"
                  label="Age from"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="ageTo"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  type="number"
                  id="filled-basic"
                  label="Age to"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </FormContent>

          <FormContent>
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Gender
              </InputLabel>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 4 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Mixed">
                      <em>Mixed</em>
                    </MenuItem>
                    <MenuItem value="Male">
                      <em>Male</em>
                    </MenuItem>
                    <MenuItem value="Female">
                      <em>Female</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="gameDuration"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  inputProps={{ tabIndex: 5 }}
                  InputProps={{ disableUnderline: true }}
                  type="number"
                  id="filled-basic"
                  label="Game duration"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Status
              </InputLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 6 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="true">
                      <em>Active</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>Inactive</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormContent>
        </Grid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div>
          <StyledButton
            variant="contained"
            onClick={() => {
              setModal(true)
            }}
            style={{
              backgroundColor: 'red',
              color: '#fff',
            }}
          >
            DELETE
          </StyledButton>
        </div>

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/divisions')
            }}
            sx={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '83px' }}
          >
            {updateLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
      {modal && (
        <DeleteModal
          setModal={setModal}
          label="division"
          handleDelete={handleDelete}
        />
      )}
    </Box>
  )
}

export default DivisionEditForm
