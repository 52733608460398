import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import DivisionEditForm from 'manage-tritag/components/pages/divisions/edit-form'

const DivisionEditPage = (props: PageProps) => {
  return (
    <Layout label1="Divisions" label2="Update" url="/divisions">
      <DivisionEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(DivisionEditPage)
